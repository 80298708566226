@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(/src/assets/fonts/Inter-Light.ttf) format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(/src/assets/fonts/Inter-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(/src/assets/fonts/Inter-Medium.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url(/src/assets/fonts/Inter-Bold.ttf) format("truetype");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {transition: all 0.5s ease;}
.custombg {
 background: linear-gradient(40deg,#2e5bff 0%,#2effda 100%);
}
.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -15px; /*Fix for safari gap*/
  bottom:0;
  min-height: 100px;
  max-height: 150px;
}
.transition {
  transition: all 0.25s ease 0s;
  transition-duration: 0.9s;
}
.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
}

.text-shadow {
  text-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

.transition-all {
  transition: all 0.25s ease 0s;
}

/* Screeen Slider */
.screenshotFrame {
  background-image: url(https://appco.themetags.com/img/phone-frame2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  width: 350px;
  height: 610px;
  position: absolute;
  top: -10px;
  left: calc(50% + 0.5px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 2;
  border-radius: 40px;
}
@media (min-width: 320px) and (max-width: 1200px){
  .screenshotFrame {
      display: none;
  }
}
/* Screeen Slider End */

.screenshot-frame {
  background-image: url(https://appco.themetags.com/img/phone-frame2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  width: 350px;
  height: 610px;
  position: absolute;
  top: -10px;
  left: calc(50% + 0.5px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 2;
  border-radius: 40px;
}
/*sccreenshot css*/
.screen-slider-content {
  position: relative
}

@media (min-width: 320px) and (max-width: 1200px){
  .screenshot-frame{
      display: none;
  }
}
.screen-carousel .owl-item img {
  -webkit-transform: scale(.85);
  transform: scale(.85);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid #ebebeb;
  border-radius: 30px
}

.screen-carousel .owl-item.active.center img {
  -webkit-transform: scale(1);
  transform: scale(1)
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
 .owl-carousel,.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0,0,0)
}

.owl-carousel .owl-item,.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0)
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none
}

.owl-carousel .owl-item img {
  display: block;
  width: 327px;
}

.owl-carousel .owl-dots.disabled,.owl-carousel .owl-nav.disabled {
  display: none
}

.no-js .owl-carousel,.owl-carousel.owl-loaded {
  display: block
}

.owl-carousel .owl-dot,.owl-carousel .owl-nav .owl-next,.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.owl-carousel .owl-nav button.owl-next,.owl-carousel .owl-nav button.owl-prev,.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0!important;
  font: inherit
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block
}

.owl-carousel.owl-hidden {
  opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab
}

.owl-carousel.owl-rtl {
  direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
  float: right
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
  z-index: 0
}

.owl-carousel .owl-animated-out {
  z-index: 1
}

.owl-carousel .fadeOut {
  animation-name: fadeOut
}

@keyframes fadeOut {
  0% {
      opacity: 1
  }

  100% {
      opacity: 0
  }
}

.owl-height {
  transition: height .5s ease-in-out
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease
}

.owl-carousel .owl-item .owl-lazy:not([src]),.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000
}


.owl-carousel .owl-video-playing .owl-video-play-icon,.owl-carousel .owl-video-playing .owl-video-tn {
  display: none
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%
}

